<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card class="pa-6">
          <v-card-title class="elevation-1">
            Seller Details
            <v-spacer></v-spacer> </v-card-title
          ><br />
          <!-- <b-container v-if="report"> -->

          <div>
            <v-row>
              <v-img
                v-if="seller.coverimage"
                class="pa-6"
                :src="baseURL + seller.coverimage"
                height="300px"
              >
               <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#FF2323"
                    ></v-progress-circular>
                  </v-row>
                </template>
                <v-row class="py-2">
                  <v-col cols="12" class="pa-15">
                    <div v-viewer>
                    <v-avatar style="cursor:pointer" v-if="user.image" size="150">
                      <img  style="object-fit:cover; " :src="baseURL + user.image" />
                    </v-avatar>
                    </div>
                  </v-col>
                </v-row>
              </v-img>
            </v-row>
            <v-tabs v-model="tab" background-color="white" color="red" grow>
              <v-tab> Profile Details </v-tab>
              <v-tab> Bank Details </v-tab>
              <v-tab> ID Proof </v-tab>

              <!-- <v-tabs-items v-model="tab"> -->
              <v-tab-item>
                <v-card color="basil" flat>
                  <v-card-text
                    ><v-row>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">First Name</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.firstname
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">Last Name</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.lastname
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">Username</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.username
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">Phone</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.phone
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">Pincode</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.pincode
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="4" md="5">
                            <span class="item_title">Email</span>
                          </v-col>
                          <v-col cols="8" md="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.email
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="4" md="5">
                            <span class="item_title">Account Type</span>
                          </v-col>
                          <v-col cols="8" md="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.accounttype
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="4" md="5">
                            <span class="item_title">City</span>
                          </v-col>
                          <v-col cols="8" md="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{ user.city }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="4" md="5">
                            <span class="item_title">Country</span>
                          </v-col>
                          <v-col cols="8" md="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.country
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="4" md="5">
                            <span class="item_title">District</span>
                          </v-col>
                          <v-col cols="8" md="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.district
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="4" md="5">
                            <span class="item_title">Locality</span>
                          </v-col>
                          <v-col cols="8" md="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.locality
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="4" md="5">
                            <span class="item_title">Street Address</span>
                          </v-col>
                          <v-col cols="8" md="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.streetAddress
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="4" md="5">
                            <span class="item_title">Organization</span>
                          </v-col>
                          <v-col cols="8" md="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.organization
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">User Status</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.status
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">Role</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{ user.type }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">Account Created On</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2"
                              >{{ formatDate(user.account_create_at) }}
                            </span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col> </v-row
                  ></v-card-text>
                </v-card>
              </v-tab-item>
              <!-- </v-tabs-items> -->
              <!-- <v-tabs-items v-model="tab"> -->
              <v-tab-item>
                <v-card color="basil" flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">Account Holder</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              seller.accountholder
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">Account Number</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              seller.accountnumber
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">Bank Name</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              seller.bankname
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">Branch</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              seller.branch
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">GST Id</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              seller.gstid
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">IFSC Code</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              seller.ifsccode
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col> </v-row
                  ></v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-layout>
                  <v-layout wrap>
                    <v-flex
                      v-for="(item, i) in seller.idproof"
                      :key="i"
                      md3
                      pa-4
                    >
                      <v-card tile flat
                        ><br />
                        <div v-viewer style="display:flex;">
                        <img
                          :src="baseURL + item"
                          height="200px"
                          style="cursor:pointer; object-fit:contain; width:100%;"
                        ></div>
                      </v-card>
                    </v-flex>
                    <v-flex v-if="!seller.idproof">
                    <h4> Oops!! No data found </h4> 
                    </v-flex>
                  </v-layout>
                </v-layout>
              </v-tab-item>
            </v-tabs>
            <!-- </v-tabs-items> -->
          </div>
          <v-row class="justify-center">
            <div class="px-2 mt-5">
              <template>
                <v-dialog v-model="dialog" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on="on" v-bind="attrs" outlined color="red"
                      >Remove Seller</v-btn
                    >
                  </template>
                  <v-card>
                    <v-card-title
                      >Are you sure you want to remove this Seller?
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialog = false"
                        >Cancel</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="rejectSeller()"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
            </div>
            <div class="px-2 mt-5">
              <v-btn outlined color="red" @click="approveSeller(user._id)"
                >Approve Seller</v-btn
              >
            </div>
          </v-row>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["sellerid"],
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      tab: null,
      msg: null,
      reason: null,
      user: [],
      seller: [],
      product: [],
      dialog: false,
      appLoading: false,
      userStatus: null,
      userRole: localStorage.getItem("role"),
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/seller/info",
        params: {
          id: this.sellerid,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.user = response.data.data;
            this.seller = response.data.data.seller;
            // this.userStatus = this.user.status;
            console.log(this.user);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    productInfo() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/product/getbyseller",
        params: {
          sellerid: this.sellerid,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.product = response.data.data;
          this.photos = this.product.photos;
          this.categoryArray = response.data.data.category;
        })
        .catch((e) => {
          this.ServerError = true;
          console.log(e);
        });
    },
    approveSeller() {
      axios({
        method: "POST",
        url: "seller/approve/",
        data: {
          id: this.sellerid,
          approve: true,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.dialog = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Approved";
            this.showsnackbar = true;
            // this.getData();
            this.$router.go(-1);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    rejectSeller() {
      axios({
        method: "GET",
        url: "/seller/delete",
        params: {
          id: this.sellerid,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.dialog = false;
          this.appLoading = false;
          if (response.data.status) {
            // this.getData();
            this.$router.go(-1);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    closeDelete() {
      this.dialog = false;
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-size: 14px;
}
.item_value {
  font-size: 13px;
}
</style>